import React, { Component } from 'react';
import {
  Redirect,
} from "react-router-dom";

import config from '../config';
import Context from '../Context';
import Heading from './Heading';
import Page from './Page';
import TimetableDays from './TimetableDays';
import TimetableHours from './TimetableHours';
import TimetableSlots from './TimetableSlots';
import * as time from '../utils/time';
import calendar from '../icons/calendar.svg';

const stepCurrent = 'timetable';
const stepIdx = config.signupStepUris.indexOf(stepCurrent);
const stepNext = config.signupStepUris[stepIdx + 1];
const stepPrev = stepIdx > 0 ? config.signupStepUris[stepIdx - 1] : null;

class Timetable extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      loading: true,
      forwardToDashboard: false,
      days: [],
      hours: {
        open: config.timetable.hoursOpenDefault,
        close: config.timetable.hoursCloseDefault,
      },
      slots: [],
      tooltip: false,
    };
  }

  async componentDidMount() {
    document.title = config.pageTitles['timetable'];
    // TODO handle errors
    const partner = await this.context.api.get(`/partner/${this.context.partner_id}`);
    const partnerSlots = await this.context.api.get(`/partner/${this.context.partner_id}/slot`);

    const weekdaysByHours = {};
    for (let slot of partnerSlots.data.data) {
      const hours = [slot.hour_open, slot.hour_close,].join('-');
      if (!weekdaysByHours[hours]) {
        weekdaysByHours[hours] = [];
      }
      weekdaysByHours[hours].push(slot.weekday);
    }

    const slots = Object.keys(weekdaysByHours)
      .map(h => {
        const hours = h.split('-');
        return {
          hours: {
            open: hours[0],
            close: hours[1],
          },
          days: weekdaysByHours[h].map(d =>   
            config.timetable.days[d - 1]
          ),
        };
      });

    this.setState({
      ...this.state,
      loading: false,
      forwardToDashboard: partner.data.data.status !== 'incomplete',
      slots,
    });
  }

  async save() {
    this.setState({
      ...this.state,
      loading: true,
    });

    const slots = this.state.slots
      .flatMap(slot => slot.days
        .map(day => ({
          weekday: config.timetable.days.indexOf(day) + 1,
          hour_open: slot.hours.open,
          hour_close: slot.hours.close,
        })));
  
    // TODO handle errors
    await this.context.api.put(`/partner/${this.context.partner_id}/slot`, {
      slots,
    });

    if (this.state.forwardToDashboard) {
      this.setState({
        ...this.state,
        redirect: '/dashboard',
      });
      return;
    }

    this.setState({
      ...this.state,
      redirect: '/' + stepNext
    });
  }

  backClicked() {
    this.setState(() => ({
      ...this.state,
      redirect: '/' + stepPrev,
    }));
  }

  middleClicked() {
    this.setState(() => ({
      ...this.state,
      tooltip: true,
    }));
  }

  async forwardClicked() {
    if (!this.state.days.length) {
      await this.save();
      return;
    }

    const slot = {
      days: this.state.days,
      hours: this.state.hours,
    };
    const slots = [...this.state.slots, slot];
    this.setState(() => ({
      ...this.state,
      days: [],
      hours: {
        open: config.timetable.hoursOpenDefault,
        close: config.timetable.hoursCloseDefault,
      },
      slots,
    }));
  }

  tooltipClicked() {
    this.setState(() => ({
      ...this.state,
      tooltip: false,
    }));
  }

  dayClicked(day) {
    let days = this.state.days;
    if(days.indexOf(day) === -1) {
      days = [...days, day];
    } else {
      days = days.filter(d => d !== day);
    }

    this.setState(() => ({
      ...this.state,
      tooltip: false,
      days,
    }));
  }

  hourChanged(type, direction) {
    let hour = this.state.hours[type];

    if (direction === 'up') {
      hour = time.subtract(hour, config.timetable.hoursInterval);
    } else {
      hour = time.add(hour, config.timetable.hoursInterval);
    }

    const hours = this.state.hours;
    hours[type] = hour;
    this.setState(() => ({
      ...this.state,
      hours,
    }));
  }

  slotDeleted(index) {
    const slots = this.state.slots;
    slots.splice(index);
    this.setState(() => ({
      ...this.state,
      slots,
    }));
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <Page
        loading={this.state.loading}
        className="page page--timetable"
        showBack={!this.state.days.length && !this.state.forwardToDashboard && 0}
        showMiddle={!this.state.days.length && this.state.slots.length && this.state.slots.length < config.timetable.slotsLimit && !this.state.tooltip}
        showForward
        valueMiddle='Add Additional'
        valueForward={this.state.days.length ? 'Confirm' : null}
        onBack={this.backClicked.bind(this)}
        onMiddle={this.middleClicked.bind(this)}
        onForward={this.forwardClicked.bind(this)}
      >
        <Heading
          title="Working Hours"
          image={calendar}
        >
          What are your working hours? Tell us the days and times you’re generally available. If alternate commitments pop up, schedule them in your Google Calendar and we won’t let people book you when you’re busy.
        </Heading>

        <TimetableDays
          days={config.timetable.days}
          activeDays={this.state.days}
          tooltip={this.state.tooltip ? 'Tap on additional dates to schedule additional hours.' : null}
          onTooltipClick={this.tooltipClicked.bind(this)}
          onClick={this.dayClicked.bind(this)}
        />

        <TimetableSlots
          active={!this.state.days.length}
          slots={this.state.slots}
          onDelete={this.slotDeleted.bind(this)}
        />

        <TimetableHours
          active={this.state.days.length}
          min={config.timetable.hoursMin}
          max={config.timetable.hoursMax}
          interval={config.timetable.hoursInterval}
          open={this.state.hours.open}
          close={this.state.hours.close}
          onChange={this.hourChanged.bind(this)}
        />
      </Page>
    );
  }
}

export default Timetable
