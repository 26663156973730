import React, { Component } from 'react';

import Input from './Input';
import Button from './Button';

class PartnerLink extends Component {
  copyToClipboard() {
    if (!this.props.link) {
      return;
    }
    // TODO this might not work on all browsers
    navigator.clipboard.writeText(this.props.link);
  }

  render() {
    if (!this.props.link) {
      return null;
    }
    
    let dashboard = null;
    if (this.props.dashboard) {
      dashboard = (
        <Button
          modifiers={['pill', 'wide',]}
          to="/dashboard"
        >
          Account Dashboard
        </Button>
      );
    }

    let copy = null;
    if (this.props.copy) {
      copy = (
        <Button
          modifiers={['pill', 'wide',]}
          onClick={this.copyToClipboard.bind(this)}
        >
          Copy Link to Clipboard
        </Button>
      );
    }

    return (
      <div className="partner-link">
        <Input
          label="Link"
          readOnly
          value={this.props.link}
          onClick={this.copyToClipboard.bind(this)}
        />
        {copy}
        {dashboard}
      </div>
    )
  }
}

export default PartnerLink
