import React, { Component } from 'react';
import {
  Redirect,
} from "react-router-dom";

import config from '../config';
import Context from '../Context';
import Heading from './Heading';
import Banner from './Banner';
import Page from './Page';
import PartnerLink from './PartnerLink';
import chart from '../icons/chart.svg';

class DashboardMetric extends Component {
  render() {
    let dollar = null;
    if (this.props.dollars) {
      //TODO actual dollar icon
      dollar = '$';
    }
    return (
      <div className="dashboard-metrics__metric">
        {this.props.title}
        <div className="dashboard-metrics__metric__value">
          {dollar}{this.props.value}
        </div>
      </div>
    );
  }
}

class Dashboard extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);

    this.state = {
      link: null,
      // TODO fetch metrics
      metrics: {
        booked: 0,
        collected: 0,
      },
    };
  }

  async componentDidMount() {
    document.title = config.pageTitles['dashboard'];
    // TODO handle errors
    const partner = await this.context.api.get(`/partner/${this.context.partner_id}`);

    if (partner.data.data.status === 'incomplete') {
      this.setState({
        ...this.state,
        redirect: '/' + config.signupStepUris[0],
      });
      return;
    }

    this.setState({
      ...this.state,
      link: partner.data.data.link,
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <Page
        className="page page-dashboard"
        showContact
      >
        <Banner 
          auth={this.context.auth}
          showEdit
        />

        <Heading
          title="Account Metrics"
          image={chart}
        >
          Here we will keep track of your lifetime account metrics. 
        </Heading>

        <div className="dashboard-metrics">
          <DashboardMetric
            title="Clients Booked"
            value={this.state.metrics.booked}
          />
          <DashboardMetric
            title="Deposits Collected"
            dollars
            value={this.state.metrics.booked}
          />
        </div>

        <PartnerLink
          link={this.state.link}
        />
      </Page>
    );
  }
}

export default Dashboard
