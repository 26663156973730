import React, { Component } from 'react';
import {
  Redirect,
} from "react-router-dom";

import config from '../config';
import Context from '../Context';
import Button from './Button';
import Wrapper from './Wrapper';
import iglink from '../icons/iglink.svg';
import iphoneIglink from '../images/iphone-iglink.png';
import iphoneCutout from '../images/iphone-cutout.png';
import iphoneHand from '../images/iphone-hand.png';
import demo from '../videos/demo.mp4';

class LandingVideo extends Component {
  render () {
    return (
      <video 
        className={this.props.className}
        autoPlay
        playsInline
        loop
        muted
      >
        <source
          src={demo}
          type="video/mp4"
        ></source>
      </video>
    );
  }
}

class WrapperLanding extends Component {
  render() {
    return (
      <Wrapper
        vertical
        modifiers={['duo',]}
      >
        <div className="landing-phones">
          <div className="landing-phones__item">
            <img
              className="landing-phones__img"
              src={iphoneIglink}
              alt="" />
          </div>
          <div class="landing-phones__item">
            <LandingVideo className="landing-phones__video" />
            <img
              className="landing-phones__img"
              src={iphoneCutout}
              alt="" />
            <img
              className="landing-phones__img landing-phones__img--cutout"
              src={iphoneCutout}
              alt="" />
          </div>
        </div>
        <div className="landing">
          {this.props.children}
        </div>
      </Wrapper>
    );
  }
}

class Landing extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      video: false,
    };
  }

  async componentDidMount() {
    document.title = config.pageTitles['landing'];
    console.log(this.context);
    const partner = await this.getPartner();
    console.log(partner);
    if (!partner || !partner.data || !partner.data.data || !partner.data.data.partner_id) {
      return;
    }

    this.setState({
      ...this.state,
      loading: true,
    });
    
    if (partner.data.message) {
      this.context.token = partner.data.message;
      localStorage.setItem('token', partner.data.message);
    }
    this.context.partner_id = partner.data.data.partner_id;
    localStorage.setItem('partner_id', partner.data.data.partner_id);

    if (partner.data.data.status === 'incomplete') {
      this.setState({
        ...this.state,
        redirect: '/' + config.signupStepUris[0],
      });
      return;
    }

    this.setState({
      ...this.state,
      redirect: '/dashboard',
    });
  }

  async getPartner() {
    if (this.context && this.context.token && this.context.partner_id) {
      // TODO handle errors
      // TODO this wont give us a jwt even if we need it, fix that
      const partner = await this.context.api.get(`/partner/${this.context.partner_id}`);
      if (partner) {
        return partner;
      }
    }

    const params = new URLSearchParams(this.props.location.search);
    const code = params.get('code'); 
    if (!code) {
      return;
    }
    console.log(code);

    // TODO handle errors
    return this.context.api.post(`/partner`, {
      instagram_code: code,
    });
  }

  learnMoreClicked() {
    this.setState({
      ...this.state,
      video: true,
    });
  }

  videoClicked() {
    this.setState({
      ...this.state,
      video: false,
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    // Should be set to this page, i.e. /
    const redirectUri = encodeURIComponent(config.instagram.redirectUri);
    const urlParams = [
      `app_id=${config.instagram.appId}`,
      `redirect_uri=${redirectUri}`,
      `scope=user_profile,user_media`,
      `response_type=code`,
    ];
    const instagramUrl = `https://api.instagram.com/oauth/authorize?${urlParams.join('&')}`;

    let video = null;
    if (this.state.video) {
      video = (
        <div
          className="video-modal"
          onClick={this.videoClicked.bind(this)}
        >
          <img
            class="video-modal__logo" 
            src={iglink} />
          <div className="video-modal__player">
            <LandingVideo className="video-modal__video" />
            <img
              className="video-modal__phone"
              src={iphoneCutout}
              alt="" />
            <img
              className="video-modal__phone video-modal__phone--cutout"
              src={iphoneCutout}
              alt="" />
          </div>
          <div className="video-modal__text">
            Iglink allows you to seamlessly book clients and process payments on Instagram through a simple link in your bio.
          </div>
        </div>
      );
    }
  
    return (
      <WrapperLanding>
        <h1 className="landing__heading">iglink</h1>
        <p className="landing__subheading">Sign up and receive a simple link with which to book clients natively within Instagram.<br/>
        For free.</p>

        <div className="landing__demo">
          <img
            className="landing__hand"
            src={iphoneHand}
            alt="" />
          <Button
            className="btn landing__learn-more"
            onClick={this.learnMoreClicked.bind(this)}
          >
            Learn More
          </Button>
        </div>

        <Button
          href={instagramUrl}
        >
          Connect to Instagram
        </Button>

        <p className="landing__info">Sign in with Instagram now, link your Google Calendar and immediately receive a link with which to begin booking appointments and taking payments directly from your Instagram.</p>

        {video}
      </WrapperLanding>
    );
  }
}

export default Landing
