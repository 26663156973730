import React, { Component } from 'react';

class Tooltip extends Component {
  render() {
    return (
      <div
        className="tooltip"
        onClick={this.props.onClick}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Tooltip
