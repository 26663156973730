import React, { Component } from 'react';

import config from '../config';
import Context from '../Context';
import Button from './Button';
import Input from './Input';
import Loader from './Loader';

class BannerProgress extends Component {
  render() {
    if (!this.props.progress) {
      return null;
    }
    const lines = [];

    for (let i = config.banner.progressMin; i <= config.banner.progressMax; i++) {
      let className = 'banner-progress__line';
      if (this.props.progress >= i) {
        className = className + ' banner-progress__line--active';
      }
      lines.push((
        <div 
          key={i}
          className={className}
        />
      ));
    }

    return (
      <div className="banner-progress">
        {lines}
      </div>
    );
  }
}

class Banner extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      username: null,
      image_url: null,
      name: null,
      location_name: null,
      location_area: null,
      rate_usd: null,
    };
  }

  async componentDidMount() {
    // TODO handle errors
    const partner = await this.context.api.get(`/partner/${this.context.partner_id}`);

    this.setState({
      ...this.state,
      loading: false,
      username: partner.data.data.username,
      image_url: /*partner.data.data.image_url*/'https://via.placeholder.com/150',
      name: partner.data.data.name,
      location_name: partner.data.data.location_name,
      location_area: partner.data.data.location_area,
      rate_usd: partner.data.data.rate_usd,
    });
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    this.timer = null;
    return this.save();
  }

  async save() {
    clearTimeout(this.timer);
    // TODO handle errors
    await this.context.api.patch(`/partner/${this.context.partner_id}`, {
      name: this.state.name,
      location_name: this.state.location_name,
      location_area: this.state.location_area,
      rate_usd: this.state.rate_usd,
    });
  }

  valueChanged(type, event) {
    clearTimeout(this.timer);
    const newState = {
      ...this.state,
    };
    newState[type] = event.target.value;
    this.setState(newState);
    this.timer = setTimeout(this.save.bind(this), 1000);
  }

  dollarsPerHour(usd) {
    if (!usd) {
      return this.dollarsPerHour(125);
    }
    let dollarsAmount = Math.max(
      1, 
      Math.floor((usd / config.banner.usdInterval) - 1));
    dollarsAmount = Math.min(dollarsAmount, 
      Math.ceil(config.banner.usdLimit / config.banner.usdInterval));
    //TODO actual dollar icon
    return '$'.repeat(dollarsAmount);
  }

  render() {
    let tooltip = null;
    if (this.props.activeTooltip) {
      tooltip = (
        <div className="banner__tooltip">
          These grey fields are required additional information for your clients. Tap on them. 
        </div>
      );
    }

    let edit = null;
    if (this.props.showEdit) {
      edit = (
        <div className="banner-edit">
          <Button
            to="/contact-info"
            modifiers={['square-white',]}
          >
            Edit Account
          </Button>
          <Button
            to="/timetable"
            modifiers={['square-white',]}
          >
            Edit Availability
          </Button>
        </div>
      );
    }

    let head = (
      <div className="banner-head">
        <img
          className="banner-head__image"
          src={this.state.image_url}
          alt={this.state.username} />
        <div className="banner-head__info">
          <h2 className="banner-head__username">
            {this.state.username}
          </h2>
          <div className="banner-head__group">
            <Input
              modifiers={['inline',]}
              placeholder="add studio name"
              value={this.state.location_name}
              maxLength="50"
              onChange={this.valueChanged.bind(this, 'location_name')}
            />
            <div className="banner-head__group__separator">|</div>
            <Input 
              modifiers={['inline',]}
              placeholder="add city"
              value={this.state.location_area}
              maxLength="50"
              onChange={this.valueChanged.bind(this, 'location_area')}
            />
          </div>
          <div className="banner-head__group">
            <div className="banner__head__dollars">    
              {this.dollarsPerHour(this.state.rate_usd)}
            </div>
            <div className="banner-head__group__separator">|</div>
            <Input
              modifiers={['inline',]}
              placeholder="add $ per hour"
              value={this.state.rate_usd}
              mask="9999/hr"
              maskRegex="\s*([0-9]+)\s*\/hr"
              onChange={this.valueChanged.bind(this, 'rate_usd')}
            />
          </div>
          {tooltip}
        </div>
      </div>
    );

    return (
      <Loader
        loading={this.state.loading}
        className="banner"
      >
        {head}
        <BannerProgress
          progress={this.props.progress}
        />
        {edit}
      </Loader>
    );
  }
}

export default Banner