import React, { Component } from 'react';
import {
  Redirect,
} from "react-router-dom";

import config from '../config';
import Context from '../Context';
import Page from './Page';
import Button from './Button';
import Input from './Input';
import Banner from './Banner';
import Heading from './Heading';
import Tooltip from './Tooltip';
import roundx from '../utils/roundx';
import piggybank from '../icons/piggybank.svg';

const stepCurrent = 'deposits';
const stepIdx = config.signupStepUris.indexOf(stepCurrent);
const stepNext = config.signupStepUris[stepIdx + 1];
const stepPrev = stepIdx > 0 ? config.signupStepUris[stepIdx - 1] : null;

class DespositsTier extends Component {
  render () {
    let className = 'deposits-tiers__tier';
    if (this.props.disabled) {
      className = className + ' deposits-tiers__tier--disabled';
    }

    return (
      <div
        className={className}
        onClick={this.props.onClick.bind(this)}
      >
        <Input
          modifiers={['pill', (this.props.white ? 'pill-white' : null), 'price',]}
          disabled={this.props.disabled}
          value={this.props.price_usd}
          mask="$9999 Deposit"
          maskRegex="\$\s*([0-9]+)\s*Deposit"
          alwaysShowMask
          onChange={this.props.onChange.bind(this, 'price_usd')}
        />
        <Input
          modifiers={['inline', 'offering',]}
          disabled={this.props.disabled}
          value={this.props.offering}
          placeholder="Edit offering"
          maxLength="100"
          onChange={this.props.onChange.bind(this, 'offering')}
        />
      </div>
    );
  }
}

class DepositsList extends Component {
  render() {
    let tooltip = null;
    if (this.props.tooltip) {
      tooltip = (
        <Tooltip
          onClick={this.props.onTooltipClick}
        >
          {this.props.tooltip}
        </Tooltip>
      );
    }

    let add = null;
    if (this.props.showAdd) {
      add = (
        <Button
          onClick={this.props.onAddClick}
        >
          Add Tier
        </Button>
      );
    }

    return (
      <div className="deposits-tiers">
        {this.props.tiers.map((tier, index) => {
          const disabled = this.props.activeIdx !== null && this.props.activeIdx !== index;
          const beingEdited = this.props.activeIdx !== null && this.props.activeIdx === index;
          const last = index === (this.props.tiers.length - 1);
          return (
            <DespositsTier
              key={index}
              price_usd={tier.price_usd}
              offering={tier.offering}
              disabled={disabled}
              white={beingEdited || !last || this.props.tiers.length < config.deposits.tiersLimit}
              onClick={this.props.onClick.bind(this, index)}
              onChange={this.props.onChange.bind(this, index)}
            />
          );
        })}
        {add}
        {tooltip}
      </div>
    );
  }
}

class Deposits extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      loading: true,
      tooltip: false,
      tiers: [],
      editingTierIdx: null,
    };
  }

  async componentDidMount() {
    document.title = config.pageTitles['deposits'];
    // TODO handle errors
    const partnerTiers = await this.context.api.get(`/partner/${this.context.partner_id}/tier`);

    let tiers = partnerTiers.data.data.map(tier => ({
      price_usd: tier.price_usd,
      offering: tier.offering,
    }));
    if (!tiers.length) {
      tiers = [];
      // TODO handle errors
      const partner = await this.context.api.get(`/partner/${this.context.partner_id}`);
      const rateBase = partner.data.data.rate_usd;
      if (rateBase) {
        const rateLow = roundx(rateBase * config.deposits.defaultPriceLow, 5);
        const rateHigh = roundx(rateBase * config.deposits.defaultPriceHigh, 5);
        tiers = [
          { price_usd: rateLow, },
          { price_usd: rateBase, },
          { price_usd: rateHigh, },
        ];
      }
    }

    this.setState({
      ...this.state,
      loading: false,
      tiers,
    });
  }

  backClicked() {
    this.setState(() => ({
      ...this.state,
      redirect: '/' + stepPrev,
    }));
  }

  middleClicked() {
    if (this.state.editingTierIdx === null) {
      this.setState(() => ({
        ...this.state,
        tooltip: !this.state.tooltip,
      }));
      return;
    }

    const newTiers = [...this.state.tiers];
    newTiers.splice(this.state.editingTierIdx, 1);
    this.setState(() => ({
      ...this.state,
      tooltip: false,
      tiers: newTiers,
      editingTierIdx: null,
    }));
  }

  async forwardClicked() {
    if (this.state.editingTierIdx !== null) {
      const newTiers = this.state.tiers
        .sort((a, b) => a.price_usd > b.price_usd ? 1 : - 1);
      this.setState(() => ({
        ...this.state,
        tiers: newTiers,
        editingTierIdx: null,
      }));
      return;
    }

    this.setState({
      ...this.state,
      loading: true,
    });

    // TODO handle errors
    await this.context.api.put(`/partner/${this.context.partner_id}/tier`, {
      tiers: this.state.tiers,
    });

    this.setState({
      ...this.state,
      redirect: '/' + stepNext
    });
  }

  tierClicked(idx) {
    if (this.state.editingTierIdx !== null) {
      return;
    }
    this.setState({
      ...this.state,
      editingTierIdx: idx,
    });
  }

  tierChanged(idx, field, event) {
    if (this.state.editingTierIdx === null) {
      return;
    }

    const newTiers = [...this.state.tiers];
    const value = field === 'price_usd' ? parseInt(event.target.value) : event.target.value;
    if (field !== 'price_usd' || !isNaN(value)) {
      newTiers[idx][field] = value;
    }
    this.setState({
      ...this.state,
      tiers: newTiers,
    });
  }

  addClicked() {
    if (this.state.editingTierIdx !== null) {
      return;
    }

    const newTiers = [...this.state.tiers];
    newTiers.push({});
    this.setState({
      ...this.state,
      tiers: newTiers,
      editingTierIdx: newTiers.length - 1,
    });
  }

  tooltipClicked() {
    this.setState(() => ({
      ...this.state,
      tooltip: false,
    }));
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <Page
        loading={this.state.loading}
        className="page page--deposits"
        showBack={stepIdx > 0}
        showMiddle={this.state.tiers.length}
        showForward
        valueMiddle={this.state.editingTierIdx !== null ? 'Remove Tier' : 'Edit Deposits'}
        valueForward={this.state.editingTierIdx !== null ? 'Confirm' : null}
        onBack={this.backClicked.bind(this)}
        onMiddle={this.middleClicked.bind(this)}
        onForward={this.forwardClicked.bind(this)}
      >
        <Banner 
          auth={this.context.auth}
          progress={config.banner.progress['deposits']}
        />

        <Heading
          title="Edit Deposits"
          image={piggybank}
        >
          Clients won’t be charged until you accept their request via our emailed link. Deposits go toward the final price of the tattoo and decrease no shows.
        </Heading>

        <DepositsList
          tooltip={this.state.tooltip ? 'Tap on a deposit to edit the amount and the offering.' : null}
          showAdd={this.state.tiers.length < config.deposits.tiersLimit && this.state.editingTierIdx === null}
          tiers={this.state.tiers}
          activeIdx={this.state.editingTierIdx}
          onTooltipClick={this.tooltipClicked.bind(this)}
          onAddClick={this.addClicked.bind(this)}
          onClick={this.tierClicked.bind(this)}
          onChange={this.tierChanged.bind(this)}
        />
      </Page>
    );
  }
}

export default Deposits
