import React, { Component } from 'react';

import Button from './Button';
import arrow2Right from '../icons/arrow2-right.svg';

class ButtonArrow extends Component {
  render() {
    const modifiers = ['pill',];
    if (!this.props.value) {
      modifiers.push('arrow');
    }
    return (
      <Button
        modifiers={modifiers}
        onClick={this.props.onClick}
      >
        {this.props.value ? this.props.value : (
          <img
            src={arrow2Right}
            alt={this.props.alt}
          />
        )}
      </Button>
    );
  }
}

class Navigation extends Component {
  render() {
    if (this.props.hidden) {
      return null;
    }
  
    let back = null;
    let middle = null;
    let forward = null;

    if (this.props.showBack) {
      back = (
        <ButtonArrow
          onClick={this.props.onBack}
          value={this.props.valueBack}
          alt="<"
        />
      );
    }

    if (this.props.showMiddle && this.props.valueMiddle) {
      middle = (
        <Button
          onClick={this.props.onMiddle}
        >
          {this.props.valueMiddle}
        </Button>
      );
    }

    if (this.props.showForward) {
      forward = (
        <ButtonArrow
          onClick={this.props.onForward}
          value={this.props.valueForward}
          alt=">"
        />
      );
    }

    if (!back && !middle && !forward) {
      return null;
    }

    if (!back && middle) {
      back = middle;
      middle = null;
    }

    return (
      <div className="navigation">
        <div className="navigation__back">
          {back}
        </div>
        <div className="navigation__middle">
          {middle}
        </div>
        <div className="navigation__forward">
        {forward}
        </div>
      </div>
    );
  }
}

export default Navigation
