import React, { Component } from 'react';

import config from '../config';
import Button from './Button';

class FooterContact extends Component {
  render() {
    return (
      <footer className="footer-contact">
        <Button
          modifiers={['pill', 'wide',]}
          href={`mailto:${config.contactEmail}`}
        >
          {config.contactEmail}
        </Button>

        We respond within 30 minutes to an hour, and escalate inquiries to phone calls as necessary. 
      </footer>
    );
  }
}

export default FooterContact
