import React, { Component } from 'react';

import Button from './Button';
import * as time from '../utils/time';
import x from '../icons/x.svg';

class TimetableSlots extends Component {
  formatDays(days) {
    if (days.length === 1) {
      return days[0];
    }

    return days
      .map(d => d.substr(0, 3))
      .join(', ');
  }

  render() {
    if (!this.props.active || !this.props.slots.length) {
      return null;
    }
    
    return (
      <div className="timetable-slots">
        {this.props.slots.map((slot, index) => {
          return (
            <div
              key={index}
              className="timetable-slots__slot btn--pill"
            >
              <div className="timetable-slots__slot__days">
                {this.formatDays(slot.days)}
              </div>
              <div className="timetable-slots__slot__hours">
                {time.format(slot.hours.open)} - {time.format(slot.hours.close)}
              </div>
              <Button
                modifiers={['del',]}
                onClick={this.props.onDelete.bind(this, index)}
              >
                <img
                  src={x}
                  alt={'X'}
                />
              </Button>
            </div>
          );
        })}
      </div>
    );
  }
}

export default TimetableSlots
