import React, { Component } from 'react';

class Loader extends Component {
  render() {
    if (this.props.loading) {
      return (
        <div className="loader"></div>
      );
    }

    return (
      <div className={this.props.className}>
        {this.props.children}
      </div>
    );
  }
}

export default Loader
