import React, { Component } from 'react';

import Incrementor from './Incrementor';
import * as time from '../utils/time';

class TimetableHours extends Component {
  render() {
    if (!this.props.active) {
      return null;
    }

    return (
      <div className="timetable-hours">
        <Incrementor
          value={time.format(this.props.open)}
          disabledUp={this.props.open === this.props.min}
          disabledDown={this.props.open === this.props.max || this.props.open === time.subtract(this.props.close, this.props.interval)}
          onClickUp={this.props.onChange.bind(null, 'open', 'up')}
          onClickDown={this.props.onChange.bind(null, 'open', 'down')}
        />
        <div className="timetable-hours__divider">
          TO
        </div>
        <Incrementor
          value={time.format(this.props.close)}
          disabledUp={this.props.close === this.props.min || this.props.close === time.add(this.props.open, this.props.interval)}
          disabledDown={this.props.close === this.props.max}
          onClickUp={this.props.onChange.bind(null, 'close', 'up')}
          onClickDown={this.props.onChange.bind(null, 'close', 'down')}
        />
      </div>
    );
  }
}

export default TimetableHours