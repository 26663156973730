import React, { Component } from 'react';
import {
  Redirect,
} from "react-router-dom";

import config from '../config';
import Context from '../Context';
import Banner from './Banner';
import Heading from './Heading';
import PartnerLink from './PartnerLink';
import Page from './Page';
import check from '../icons/check.svg';

class AllSet extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);

    this.state = {
      link: null,
    };
  }

  async componentDidMount() {
    document.title = config.pageTitles['all-set'];
    // TODO handle errors
    const partner = await this.context.api.patch(`/partner/${this.context.partner_id}`, {
      status: 'active',
    });

    if (partner.data.data.status === 'incomplete') {
      this.setState({
        ...this.state,
        redirect: '/' + config.signupStepUris[0],
      });
      return;
    }

    this.setState({
      ...this.state,
      link: partner.data.data.link,
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <Page
        className="page page--all-set"
      >
        <Banner 
          auth={this.context.auth}
          progress={config.banner.progress['all-set']}
        />

        <Heading
          title="All Set!"
          image={check}
        >
          Just copy your link, add it to your Instagram profile under “website” and begin booking clients and taking payments within Instagram immediately.
        </Heading>

        <PartnerLink
          link={this.state.link}
          copy
          dashboard
        />
      </Page>
    );
  }
}

export default AllSet
