const config = {
  domain: process.env.REACT_APP_DOMAIN,
  domainApi: process.env.REACT_APP_DOMAIN_API,
  instagram: {
    appId: process.env.REACT_APP_INSTAGRAM_APP_ID,
    redirectUri: process.env.REACT_APP_INSTAGRAM_REDIRECT_URI,
  },
  google: {
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    scope: 'profile email https://www.googleapis.com/auth/calendar',
  },
  plaid: {
    env: process.env.REACT_APP_PLAID_ENV,
    publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
  },
  contactEmail: 'support@iglink.io',
  pageTitles: {
    'landing': 'iglink',
    'google': 'Google Account - iglink',
    'timetable': 'Working Hours - iglink',
    'contact-info': 'Contact Info - iglink',
    'deposits': 'Edit Deposits - iglink',
    'bank': 'Link Bank Account - iglink',
    'all-set': 'All Set - iglink',
    'dashboard': 'Dashboard - iglink',
  },
  signupStepUris: [
    'google',
    'timetable',
    'contact-info',
    'deposits',
    'bank',
    'all-set',
  ],
  timetable: {
    days: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
    hoursMin: '00:00',
    hoursMax: '24:00',
    hoursInterval: '00:30',
    hoursOpenDefault: '12:00',
    hoursCloseDefault: '17:00',
    slotsLimit: 3,
  },
  banner: {
    usdInterval: 25,
    usdLimit: 150,
    progress: {
      'google': null,
      'timetable': null,
      'contact-info': 1,
      'deposits': 2,
      'bank': 3,
      'all-set': 3,
    },
    progressMin: 1,
    progressMax: 3,
  },
  deposits: {
    defaultPriceLow: 0.80,
    defaultPriceHigh: 1.20,
    tiersLimit: 3,
  },
}

export default config
