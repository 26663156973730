import React, { Component } from 'react';
import {
  Redirect,
} from "react-router-dom";

import config from '../config';
import Context from '../Context';
import Page from './Page';
import Banner from './Banner';
import Heading from './Heading';
import Input from './Input';
import letter from '../icons/letter.svg';

const stepCurrent = 'contact-info';
const stepIdx = config.signupStepUris.indexOf(stepCurrent);
const stepNext = config.signupStepUris[stepIdx + 1];
const stepPrev = stepIdx > 0 ? config.signupStepUris[stepIdx - 1] : null;

class ContactInfo extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      loading: true,
      forwardToDashboard: false,
      editing: false,
      full_name: null,
      email: null,
      phone: null,
    };
  }

  async componentDidMount() {
    document.title = config.pageTitles['contact-info'];
    // TODO handle errors
    const partner = await this.context.api.get(`/partner/${this.context.partner_id}`);
    const partnerContact = await this.context.api.get(`/partner/${this.context.partner_id}/contact`);

    this.setState({
      ...this.state,
      loading: false,
      forwardToDashboard: partner.data.data.status !== 'incomplete',
      full_name: partnerContact.data.data.full_name,
      email: partnerContact.data.data.email,
      phone: partnerContact.data.data.phone,
    });
  }

  backClicked() {
    this.setState(() => ({
      ...this.state,
      redirect: '/' + stepPrev,
    }));
  }

  async forwardClicked() {
    this.setState({
      ...this.state,
      loading: true,
    });

    // TODO handle errors
    await this.context.api.patch(`/partner/${this.context.partner_id}/contact`, {
      full_name: this.state.full_name,
      email: this.state.email,
      phone: this.state.phone,
    });

    if (this.state.forwardToDashboard) {
      this.setState({
        ...this.state,
        redirect: '/dashboard',
      });
      return;
    }

    this.setState({
      ...this.state,
      redirect: '/' + stepNext
    });
  }

  valueChanged(type, event) {
    const newState = {
      ...this.state,
    };
    newState[type] = event.target.value;
    this.setState(newState);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <Page
        loading={this.state.loading}
        className="page page--contact-info"
        showBack={stepIdx > 0 && !this.state.forwardToDashboard}
        showForward
        onBack={this.backClicked.bind(this)}
        onForward={this.forwardClicked.bind(this)}
      >
        <Banner 
          auth={this.context.auth}
          progress={!this.state.forwardToDashboard ? config.banner.progress['contact-info'] : null}
          showEdit={this.state.forwardToDashboard}
        />

        <Heading
          title="Contact Info"
          image={letter}
        >
          We’ll keep this information safe so we can contact you when you have a new client.
        </Heading>

        <Input
          label="Full Name"
          value={this.state.full_name}
          maxLength="50"
          onChange={this.valueChanged.bind(this, 'full_name')}
        />
        <Input
          label="Email"
          value={this.state.email}
          maxLength="254"
          onChange={this.valueChanged.bind(this, 'email')}
        />
        <Input
          label="Phone"
          value={this.state.phone}
          maxLength="15"
          onChange={this.valueChanged.bind(this, 'phone')}
        />
      </Page>
    );
  }
}

export default ContactInfo
