import React, { Component } from 'react';

import Navigation from './Navigation';
import Loader from './Loader';
import Header from './Header';
import FooterContact from './FooterContact';
import Wrapper from './Wrapper';

class Page extends Component {
  render() {
    let navigation = null;
    if (!this.props.loading) {
      navigation = (
        <Navigation
          showBack={this.props.showBack}
          showMiddle={this.props.showMiddle}
          showForward={this.props.showForward}
          valueBack={this.props.valueBack}
          valueMiddle={this.props.valueMiddle}
          valueForward={this.props.valueForward}
          onBack={this.props.onBack}
          onMiddle={this.props.onMiddle}
          onForward={this.props.onForward}
        />
      );
    }

    let contact = null;
    if (this.props.showContact) {
      contact = (
        <FooterContact />
      );
    }
    
    return (
      <div>
        <Header />
        <Wrapper
          vertical={this.props.vertical}
          modifiers={this.props.modifiers}
        >
          <Loader
            loading={this.props.loading}
            className={this.props.className}
          >
            {this.props.children}
          </Loader>
          {navigation}
          {contact}
        </Wrapper>
      </div>
    );
  }
}

export default Page
