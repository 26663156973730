import React, { Component } from 'react';
import {
  Redirect,
} from "react-router-dom";
import { GoogleLogin } from 'react-google-login';

import config from '../config';
import Context from '../Context';
import Heading from './Heading';
import WrapperLoader from './Page';
import googleCalendar from '../icons/google-calendar.svg';

const stepCurrent = 'google';
const stepIdx = config.signupStepUris.indexOf(stepCurrent);
const stepNext = config.signupStepUris[stepIdx + 1];
const stepPrev = stepIdx > 0 ? config.signupStepUris[stepIdx - 1] : null;

class Google extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      loading: true,
    };
  }

  async componentDidMount() {
    document.title = config.pageTitles['google'];
    // TODO handle errors
    const partnerGoogle = await this.context.api.get(`/partner/${this.context.partner_id}/google`);

    // TODO allow reauth here?
    if (partnerGoogle.data.data.google_user_id) {
      this.setState({
        ...this.state,
        redirect: '/' + stepNext,
      });
      return;
    }

    this.setState({
      ...this.state,
      loading: false,
    });
  }

  backClicked() {
    this.setState(() => ({
      ...this.state,
      redirect: '/' + stepPrev,
    }));
  }

  async responseGoogle(response) {
    // TODO handle errors w/ response
    const profile = response.getBasicProfile();
    const full_name = profile.getName();
    const email = profile.getEmail();

    this.setState(() => ({
      ...this.state,
      loading: true,
    }));

    // TODO handle errors
    await this.context.api.patch(`/partner/${this.context.partner_id}/google`, {
      google_user_id: response.googleId,
      google_token_id: response.tokenId,
      google_access_token: response.accessToken,
    });

    const partnerContact = await this.context.api.get(`/partner/${this.context.partner_id}/contact`);
    if (!partnerContact.data.data.full_name && !partnerContact.data.data.email) {
      await this.context.api.patch(`/partner/${this.context.partner_id}/contact`, {
        full_name,
        email,
      });
    }

    this.setState({
      ...this.state,
      redirect: '/' + stepNext,
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
  
    return (
      <WrapperLoader
        vertical
        loading={this.state.loading}
        className="page page--google"
        showBack={stepIdx > 0}
        onBack={this.backClicked.bind(this)}
      >
        <Heading
          image={googleCalendar}
          title="Google Account"
        >
          Let us connect to your Google Calendar so that we can schedule bookings directly and let clients book appointments based on your availability.
        </Heading>
      
        <GoogleLogin
          clientId={config.google.clientId}
          scope={config.google.scope}
          buttonText="Sign in with Google"
          onSuccess={this.responseGoogle.bind(this)}
          onFailure={this.responseGoogle.bind(this)}
          cookiePolicy={'single_host_origin'}
        />
      </WrapperLoader>
    );
  }
}

export default Google
