import React, { Component } from 'react';
import {
  Redirect,
} from "react-router-dom";
import PlaidLink from 'react-plaid-link'

import config from '../config';
import Context from '../Context';
import Banner from './Banner';
import Page from './Page';
import Heading from './Heading';
import bank from '../icons/bank.svg';

const stepCurrent = 'bank';
const stepIdx = config.signupStepUris.indexOf(stepCurrent);
const stepNext = config.signupStepUris[stepIdx + 1];
const stepPrev = stepIdx > 0 ? config.signupStepUris[stepIdx - 1] : null;

class Bank extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      loading: true,
    };
  }

  async componentDidMount() {
    document.title = config.pageTitles['bank'];
    // TODO handle errors
    const partnerPlaid = await this.context.api.get(`/partner/${this.context.partner_id}/plaid`);

    if (partnerPlaid.data.data.plaid_access_token) {
      this.setState(() => ({
        ...this.state,
        redirect: '/' + stepNext,
      }));
      return;
    }

    this.setState({
      ...this.state,
      loading: false,
    });
  }

  backClicked() {
    this.setState(() => ({
      ...this.state,
      redirect: '/' + stepPrev,
    }));
  }

  async handleOnSuccess(plaid_public_token, metadata) {
    console.log(plaid_public_token);
    // TOODO handle errors
    await this.context.api.patch(`/partner/${this.context.partner_id}/plaid`, {
      plaid_public_token,
    });

    this.setState(() => ({
      ...this.state,
      redirect: '/' + stepNext,
    }));
  }

  handleOnExit() {
    console.log('TODO');
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    
    return (
      <Page
        loading={this.state.loading}
        className="page page-bank"
        showBack={stepIdx > 0}
        onBack={this.backClicked.bind(this)}
      >
        <Banner 
          auth={this.context.auth}
          progress={config.banner.progress['bank']}
        />

        <Heading
          title="Link Bank Account"
          image={bank}
        >
          We need your bank details so that we can deposit cash into your account.
        </Heading>

        <PlaidLink
          className="btn btn--pill btn--wide"
          style={{}}
          clientName="Iglink"
          env={config.plaid.env}
          product={["auth", "transactions"]}
          publicKey={config.plaid.publicKey}
          onExit={this.handleOnExit.bind(this)}
          onSuccess={this.handleOnSuccess.bind(this)}
        >
          Link Bank Account
        </PlaidLink>
      </Page>
    );
  }
}

export default Bank
