import React, { Component } from 'react';

import Button from './Button';
import Tooltip from './Tooltip';

class TimetableDay extends Component {
  render() {
    let modifiers = ['pill', 'pill-white',];
    if (this.props.active) {
      modifiers = ['pill',];
    }
    return (
      <Button
        modifiers={modifiers}
        title={this.props.day}
        onClick={this.props.onClick.bind(this, this.props.day)}
      >
        {this.props.day[0]}
      </Button>
    );
  }
}

class TimetableDays extends Component {
  render() {
    var tooltip = null;
    if (this.props.tooltip) {
      tooltip = (
        <Tooltip
          onClick={this.props.onTooltipClick}
        >
          {this.props.tooltip}
        </Tooltip>
      );
    }

    return (
      <div className="timetable-days">
        {tooltip}
        {this.props.days.map((day) => {
          return <TimetableDay
            key={day}
            active={this.props.activeDays.indexOf(day) !== -1}
            day={day}
            onClick={this.props.onClick.bind(this)}
            />
        })}
      </div>
    ); 
  }
}

export default TimetableDays
