import React, { Component } from 'react';

import Button from './Button';
import arrowUp from '../icons/arrow-up.svg';

class Incrementor extends Component {
  render() {
    return (
      <div className="incrementor">
        <Button
          modifiers={['pill', 'pill-white', 'up',]}
          disabled={this.props.disabledUp}
          onClick={this.props.onClickUp}
        >
          <img
            src={arrowUp}
            alt="scroll up" />
        </Button>
        <div className="incrementor__value">
          {this.props.value}
        </div>
        <Button
          modifiers={['pill', 'pill-white', 'down',]}
          disabled={this.props.disabledDown}
          onClick={this.props.onClickDown}
        >
          <img src={arrowUp} alt="scroll down" />
        </Button>
      </div>
    );
  }
}

export default Incrementor
