import React, { Component, useContext } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import axios from 'axios';
import { setupCache } from 'axios-cache-adapter'

import config from './config';
import Context from './Context';
import Landing from './components/Landing';
import Google from './components/Google';
import ContactInfo from './components/ContactInfo';
import Timetable from './components/Timetable';
import Deposits from './components/Deposits';
import Bank from './components/Bank';
import AllSet from './components/AllSet';
import Dashboard from './components/Dashboard';

function RouteAuth({ component: Component, ...rest }) {
  const context = useContext(Context);

  return (
    <Route
      {...rest}
      render={props =>
        context.token && context.partner_id ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}

class App extends Component {
  render() {
    const context = {
      token: null,
      partner_id: null,
    };

    const token = localStorage.getItem('token');
    const partner_id = localStorage.getItem('partner_id');
    if (token && partner_id) {
      context.token = token;
      context.partner_id = partner_id;
    }

    const cache = setupCache();
    const api = axios.create({
      adapter: cache.adapter,
      baseURL: `https://${config.domainApi}`,
    });
    api.interceptors.request.use(config => {
      if (context.token) {
        config.headers.Authorization = 'Bearer ' + context.token;
      }
      return config;
    });
    api.interceptors.response.use(
      response => response,
      function (error) {
        // TODO better error handling
        window.alert(error);
        return Promise.reject(error);
      }
    );
    context.api = api;

    return (
      <Context.Provider value={context}>
        <Router>
          <Route exact path="/" component={Landing} />

          <RouteAuth path="/contact-info" component={ContactInfo} />
          <RouteAuth path="/google" component={Google} />
          <RouteAuth path="/timetable" component={Timetable} />
          <RouteAuth path="/deposits" component={Deposits} />
          <RouteAuth path="/bank" component={Bank} />
          <RouteAuth path="/all-set" component={AllSet} />

          <RouteAuth path="/dashboard" component={Dashboard} />
        </Router>
      </Context.Provider>
    );
  }
}

export default App
