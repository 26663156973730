export function prefix0(n) {
  return n < 10 ? '0'+n : n
}

export function parse(h) {
  const [_hours, _minutes,] = h.split(':');

  const hours = parseInt(_hours);
  const hours12 = hours > 12 ? hours - 12 : hours;

  const minutes = parseInt(_minutes);
  const minutes0 = prefix0(minutes);;

  const ampm = hours >= 12 && hours < 24 ? 'pm' : 'am';

  return {
    hours,
    hours12,
    minutes,
    minutes0,
    ampm,
  };
}

export function format(h) {
  const { hours12, minutes0, ampm, } = parse(h);
  return `${hours12}:${minutes0} ${ampm}`;
}

export function add(h, t) {
  const hour = parse(h);
  const toAdd = parse(t);

  let hours = hour.hours + toAdd.hours;
  let minutes = hour.minutes + toAdd.minutes;
  if (minutes >= 60) {
    hours = hours + Math.floor(minutes / 60)
    minutes = minutes % 60;
  }

  if (hours > 24 || (hours === 24 && minutes > 0)) {
    hours = 24;
    minutes = 0;
  }

  return [hours, prefix0(minutes)].join(':');
}

export function subtract(h, t) {
  const hour = parse(h);
  const toSub = parse(t);

  let hours = hour.hours - toSub.hours;
  let minutes = hour.minutes - toSub.minutes;
  if (minutes < 0) {
    hours = hours - Math.ceil(Math.abs(minutes) / 60)
    minutes = Math.abs(minutes) % 60;
  }

  if (hours < 0) {
    hours = 0;
    minutes = 0;
  }

  return [hours, prefix0(minutes)].join(':');
}
